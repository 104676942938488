@import "theme";

@import url("~leaflet/dist/leaflet.css");
@import url("~bootstrap/dist/css/bootstrap.css");

html {
  position: relative;
  height: 100%;
}

.app {
  text-align: center;
  position: absolute;
  top: 40px;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
}

.app-title {
  height: 40px;
  text-align: center;
  font-size: 1.5em;
  background-color: #222222;
  color: white;
  text-transform: capitalize;
}

.no-padding {
  padding: 0;
}

.map-reactleaflet {
  flex: 1 0;
}

.section-map {
  height: 100%;
  position: relative;
}

.map {
  flex: 1 0;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.my-div-icon {
  border: 2px solid #939393;
  border-radius: 100%;
  background-color: #ffffff;
  color: #939393;
  height: 30px;
  line-height: 27px;
  text-align: center;
  width: 30px;
}

.my-div-icon.filter {
  border-color: #000000;
  color: #000000;
}

.popup-div {
  height: 100%;
  width: 100%;
}

.incident-detail::first-letter {
  text-transform: capitalize;
}

.incident-filter {
  border-bottom: 1px solid #939393;
}

.filter-list {
  display: flex;
  flex: 200px 0 0;
  flex-direction: column;
  padding: 0;
}

.filter-list .header {
  color: #646464;
}

.filter-list .header h3 {
  font-size: 1em;
  line-height: 1.3;
  padding: 0.3em 0;
}

.scroll-y {
  overflow-y: auto;
}

.filter-list .list {
  padding: 0;
  flex: 1;
  text-align: left;
}

.filter-list .list .filter-row {
  border-bottom: 1px solid #e7e7e7;
  height: 30px;
  line-height: 1.8;
  padding-left: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.filter-list .list .filter-row:hover {
  background-color: #e7e7e7;
}

.filter-list .list .filter-row:first-child {
  border-top: 1px solid #e7e7e7;
}

@media (max-width: 768px) {
  .filter-list {
    flex: 120px 0 0;
  }

  .filter-list .list .filter-row {
    height: 44px;
    line-height: 2.4;
    max-width: 120px;
  }
}

.show-cursor {
  cursor: pointer;
}

.trail-card {
  border: none;
}

.trail-card > .card-body,
.trail-card > .card-header {
  padding: 0.25rem;
}

.leaflet-legend-control {
  height: 100%;
  width: 100%;
  background-color: white;
  display: block;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 8px;
}

.leaflet-bar a:hover {
  background-color: white;
}

.leaflet-control {
  & a:hover {
    color: currentColor;
  }
}

.legend-color-existing,
.legend-color-funded,
.legend-color-proposed {
  height: 3px;
  width: 10px;
  display: inline-block;
  margin-right: 3px;
  line-height: 12px;
  vertical-align: middle;
  text-align: left;
}

.legend-color-existing {
  background-color: black;
}

.legend-color-proposed {
  background-color: red;
}

.legend-color-funded {
  background-color: blue;
}
.legend-label {
  display: inline-block;
  line-height: 16px;
  text-align: right;
}
.legend-row {
  text-align: left;
}

div.filter-control {
  clear: none;
}

.filter-panel {
  display: flex;
  flex: 200px 0 0;
  flex-direction: column;
  padding: 0;
  margin: 5px;
}

.filter-panel .header {
  color: #646464;
}

.filter-panel .header h3 {
  font-size: 1em;
  line-height: 1.3;
  padding: 0.3em 0;
}

.scroll-y {
  overflow-y: auto;
}

.filter-panel .list {
  padding: 0;
  flex: 1;
  text-align: left;
}

select.form-control.dropdown-custom {
  max-width: 200px;
}

a.filter-control.green-filter-button {
  background-color: #66a366;
}

input.form-input-list::-webkit-calendar-picker-indicator {
  display: none;
}
